import { FOLDER_ID_REGEX } from '../constants/FOLDER_ID_REGEX';

const extractSlugIdentifier = (slug: string) => {
  return (slug || '').split('-').slice(-1)[0];
};

const urlHashHasPathData = (): boolean => {
  return !!window.location.hash && window.location.hash.startsWith('#/');
};

/**
 * The bootstrap call needs information about the folder / dashboard
 * to correctly to load the model data.
 * If a url comes in with `#/` in the middle
 * it needs to be combined and the folder
 * @returns pathname combined with hash and folder / dashboard slug
 */

export function getPathNameForBootstrapCall(): string {
  let pathname = window.location.pathname;
  if (pathname === '/') {
    pathname = '';
  }

  // Original pathname can be used for the bootstrap call
  if (!urlHashHasPathData()) {
    return pathname;
  }

  // Window property is something like  { location:/data/databases, hash:#/DB1 , search:''}
  const hashComponents = window.location.hash.split('/');

  // Example hash components of url
  //     #/data/databases/DB1
  //     #/provider-studio
  //     #/folder-test-fABsfsf
  //     #/wAfsfsAGs3
  if (hashComponents.length !== 2) {
    return pathname;
  }

  // To check for possible folder/dashboard page
  const [_, possibleDashboardOrFolderPath] = hashComponents;
  // If its a dashboard or folder page with hashes - only extract the folder / dashboard id from the slug
  // of the folder / dashboard page to pass to the bootstrap call.
  // Eg, for dashboard pages - pathname would be - {region}/{org},  hash would be - #/{dashboardId}
  // Eg, for folder pages - pathname would be - {region}/{org}, hash would be - #/{folderId}
  if (possibleDashboardOrFolderPath && FOLDER_ID_REGEX.test(possibleDashboardOrFolderPath)) {
    const folderOrDashboardSlug = extractSlugIdentifier(possibleDashboardOrFolderPath);
    pathname = pathname + folderOrDashboardSlug;
  }

  return pathname;
}
