import type { ApplicationBootstrapFetch } from '../types';
import type { LoggedInAccount } from '../types/LoggedInAccount';
import type { RequestContext } from '../types/RequestContext';
import { buildRequestOptions } from './buildRequestOptions';
import { getDecodedUserKey } from './getDecodedUserKey';
import { getKeyForLoggedInAccount } from './getKeyForLoggedInAccount';
import { getPathNameForBootstrapCall } from './getPathNameForBootstrapCall';
import { observableFetch } from './observableFetch';

export function runApplicationBootstrapFetch(account: LoggedInAccount): ApplicationBootstrapFetch {
  const requestContext: RequestContext = {
    appServerUrl: account.appServerUrl,
    userKey: getKeyForLoggedInAccount(account),
    decodedUserKey: getDecodedUserKey(account),
    role: sessionStorage.getItem('currentrole') ?? undefined,
  };

  const pathname = getPathNameForBootstrapCall();
  const path = pathname + window.location.search;
  const simpleBootstrapPath = `/bootstrap${path}`;

  const [options, headers] = buildRequestOptions(requestContext, undefined, undefined, console);
  return {
    fetchResponse: observableFetch({
      args: [`${requestContext.appServerUrl}${simpleBootstrapPath}`, { ...options, headers }],
      observableName: 'app_bootstrap',
    }),
    path: simpleBootstrapPath,
    requestContext,
  };
}
