import type { LoggedInAccount } from '../types/LoggedInAccount';
import type { LoggedInAccounts } from '../types/LoggedInAccounts';
import { getRegionAndAccountFromRegionedURL } from './getRegionAndAccountFromRegionedURL';

/*
Finds a matching account based on a regioned URL. If there are multiple users for the same account, will return the most recently used one.
*/
export const getAccountFromRegionedURL = (url: URL, accounts: LoggedInAccounts) => {
  const [account, region] = getRegionAndAccountFromRegionedURL(url);
  let match: LoggedInAccount | null = null;

  for (const userKey in accounts) {
    const curr = accounts[userKey];
    const currAccount = curr.account?.toLowerCase();
    const currRegion = curr.region?.toLowerCase();
    if (currAccount === account && currRegion === region) {
      if (!match || (curr.updated && curr.updated > (match.updated ?? 0))) {
        match = curr;
      }
    }
  }

  return match;
};
