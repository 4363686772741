import type { LoggedInAccount } from '../types/LoggedInAccount';

function isSameAccount(account1: LoggedInAccount, account2: LoggedInAccount): boolean {
  return (
    (account1.region === account2.region && account1.account === account2.account) ||
    (account1.snowflakeOrgName === account2.snowflakeOrgName &&
      account1.accountAlias === account2.accountAlias)
  );
}

/**
 * Returns account based on URL and snowflakeContext (stored in sessionStorage and/or cookie)
 */
export function getAccountFromUrlAndContext(
  accountFromUrl: LoggedInAccount | null,
  accountFromContext: LoggedInAccount | null,
): LoggedInAccount | null {
  // If
  // (a) there's no account that matches the URL or
  // (b) accountFromContext points to the same account as accountFromURL
  // (but possibly with different user), return accountFromContext
  //
  // User set in context takes precedence over user most recently logged in
  // because context can subsequently be switched after by explicit user action
  //
  // Example scenario:
  //     Log into user A ->
  //     Log into user B in same account ->
  //     Switch back to user A from AccountSwitcher ->
  //     Should determine uer A as the active account
  if (
    !accountFromUrl ||
    (accountFromContext && isSameAccount(accountFromUrl, accountFromContext))
  ) {
    return accountFromContext;
  }

  // By default, returns the most recently logged in account that matches URL
  return accountFromUrl;
}
