interface UserSettings {
  lastUsedQueryContext: {
    role: string;
    warehouse: string;
    database: string;
    schema: string;
    secondaryRoles: string;
  };
  snowflakeFeatures: Record<string, boolean | string | number>;
  snowflakeTokenId: string;
  isNewUser: boolean;
}

/** Partial response from /bootstrap */
export interface BootstrapResponse {
  BuildVersion: string;
  PageParams?: {
    appServerUrl?: string;
    csrfToken?: string;
    enableIndexJsBootstrap?: boolean;
    enablePrefetcherBootstrap?: boolean;
    enableFetchExtendedBootstrapData?: boolean;
    ddAppId?: string;
    ddClientToken?: string;
  };
  Org?: {
    settings: {
      isPolaris?: boolean;
    };
    account: string;
  };
  User?: {
    availableRoles: string[];
    avatar?: string;
    canModifyParameters?: boolean;
    defaultOrgId?: string;
    email: string;
    id?: number;
    name: string;
    settings?: UserSettings;
    username: string;
    gsCreatedOn?: string | number;
    firstName: string;
    lastName: string;
  };
}

const BOOTSTRAP_DATA_KEY = 'bootstrapData';

class BootstrapData {
  private data: BootstrapResponse | undefined;

  set(data: BootstrapResponse) {
    this.data = data;
    // We need to store this on sessionStorage in case a different version of this file is loaded.
    sessionStorage.setItem(BOOTSTRAP_DATA_KEY, JSON.stringify(data));
  }

  get(): BootstrapResponse | undefined {
    if (!this.data) {
      const data = sessionStorage.getItem(BOOTSTRAP_DATA_KEY);
      if (data) {
        this.data = JSON.parse(data);
      }
    }
    return structuredClone(this.data);
  }

  setUser(user: Required<BootstrapResponse>['User']) {
    const data = this.get();
    if (data) {
      data.User = user;
      this.set(data);
    }
  }
}

export const bootstrapData = new BootstrapData();
