/**
 * Given a string, generate an encoding to be set on a `x-snowflake-*-encoded` header. The
 * encoding should match the formatting expected by `nulib/snowflakeRoleHeaderEncoding.go`
 * and `RequestCoreAttributesFilter.java` in Cold Brew.
 *
 * @param role
 * @returns Header value
 */
export const generateSnowflakeEncodedHeaderValue = (role: string) => {
  return `1#${encodeURIComponent(role)}`;
};
