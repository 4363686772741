/*
There are two possible URL formats:

1) Regionless /:snowflakeOrgName/:accountAlias (majority of cases)
2) Regioned /:account/:region

Assume it's a regionless URL but fallback to parsing as regioned URL if we don't find a match.
*/

import type { LoggedInAccounts } from '../types/LoggedInAccounts';
import { getAccountFromRegionedURL } from './getAccountFromRegionedURL';
import { getAccountFromRegionlessURL } from './getAccountFromRegionlessURL';

export const getAccountFromUrl = (url: URL, accounts: LoggedInAccounts) => {
  return getAccountFromRegionlessURL(url, accounts) ?? getAccountFromRegionedURL(url, accounts);
};
