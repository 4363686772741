/* eslint-disable @typescript-eslint/no-explicit-any -- legacy code */
import type { NumeracyGlobal, PageParamsGlobal } from '../types';

export const numeracy: NumeracyGlobal = (window as any).numeracy || {};
Object.defineProperty(window, 'numeracy', {
  get() {
    return numeracy;
  },
  set(obj) {
    Object.assign(numeracy, obj);
    return numeracy;
  },
  configurable: true,
});

export const pageParams: PageParamsGlobal = (window as any).pageParams || {};
Object.defineProperty(window, 'pageParams', {
  get() {
    return pageParams;
  },
  set(obj) {
    Object.assign(pageParams, obj);
    return pageParams;
  },
  configurable: true,
});

const build = process.env.build || {};
(window as any).build = build;
numeracy.clientVersion = (build as any).version;
numeracy.appServerUrl = (build as any).appServerUrl;
numeracy.injectableAfterLoad = true;
