/* eslint-disable @snowflake/no-hardcoded-domain -- [TODO: APPS-40418] */

// Must be kept in sync with domainConfiguration.go
const privatelinkSuffixes = [
  '.privatelink.testdns.snowflakecomputing.com',
  '.privatelink.snowflakecomputing.com',
  '.privatelink.int.snowflakecomputing.com',
  '.privatelink.int.snowflake.local',
  '.privatelink.snowflake.local',
  '.privatelink.snowflake.snowsaas.cn',
  '.privatelink.snowflakecomputing.com.cn',
  '.privatelink.snowflakecomputing.cn',
];

function splitPrivatelinkSuffix(url?: string): string {
  return privatelinkSuffixes.find((suffix) => url?.endsWith(suffix)) || '';
}

export function isPrivatelink(host?: string): boolean {
  return splitPrivatelinkSuffix(host) !== '';
}
