import { CSRF_TOKEN_STORAGE_KEY } from '../constants/csrf';

function setupCsrfTokenStorage() {
  const csrfTokens: Record<string, string> = (() => {
    try {
      const storedTokens = sessionStorage.getItem(CSRF_TOKEN_STORAGE_KEY);
      if (!storedTokens) return {};
      return JSON.parse(storedTokens);
    } catch {
      return {};
    }
  })();

  /** Set the csrfToken for a given namespace, usually a domain. */
  function setCsrfToken(namespace: string, token: string) {
    csrfTokens[namespace] = token;
    sessionStorage.setItem(CSRF_TOKEN_STORAGE_KEY, JSON.stringify(csrfTokens));
  }

  /** Get the csrfToken for a given namespace, usually a domain */
  function getCsrfToken(namespace: string) {
    return csrfTokens[namespace];
  }

  return { setCsrfToken, getCsrfToken };
}

export const { setCsrfToken, getCsrfToken } = setupCsrfTokenStorage();
