/* 
This script serves as the entry point for Snowsight.
It's purpose is to load the version of FE assets that match the version of the active account deployment.
*/

import type { RetryConfig, RetryError } from 'ts-retry-promise';
import { NotRetryableError, retry } from 'ts-retry-promise';
import { setCsrfToken } from '../../api/functions/csrfToken';
import { numeracy } from '../../initialization/constants';
import { buildRequestOptions } from '../../initialization/functions/buildRequestOptions';
import { getActiveAccount } from '../../initialization/functions/getActiveAccount';
import { getDecodedUserKey } from '../../initialization/functions/getDecodedUserKey';
import { getKeyForLoggedInAccount } from '../../initialization/functions/getKeyForLoggedInAccount';
import { observableFetch } from '../../initialization/functions/observableFetch';
import { runApplicationBootstrapFetch } from '../../initialization/functions/runApplicationBootstrapFetch';
import type { NumeracyGlobal } from '../../initialization/types';
import type { BootloaderAnalytics } from '../../initialization/types/BootloaderAnalytics';
import type { BootstrapExperiments } from '../../initialization/types/BootstrapExperiments';
import type { LoggedInAccount } from '../../initialization/types/LoggedInAccount';
import type { RequestContext } from '../../initialization/types/RequestContext';
import { AccountType } from './accountType';
import type { BootstrapResponse } from './bootstrapData';
import { bootstrapData } from './bootstrapData';

// A build manifest is an asset produced by the bundler
// that helps us determine what static assets are necessary
// to load for our main entrypoint
type BuildManifest = {
  [key: string]: { file: string } | NonNullable<unknown>;
  entrypoints: Record<string, string[]>;
};

type AssetsLoader = (
  configuration: BootstrapResponse,
  account: LoggedInAccount | null,
) => Promise<unknown>;

// This will be made available by the bundler, and is used to remove
// code that is only intended for development
declare const DEV_SERVER: boolean;

/*
The main numeracy bundle will populate window.numeracy on load.
*/
declare global {
  interface Window extends BootstrapExperiments {
    numeracy: NumeracyGlobal;
    NUMERACY_VERSION_OVERRIDE?: string;
    bootloaderAnalytics: BootloaderAnalytics;
  }
}

const RETRY_CONFIG: Partial<RetryConfig> = {
  retries: 1,
  delay: 10, // The amount of time, in milliseconds, to wait before retrying a failed action
  timeout: 'INFINITELY',
};

const MANIFEST_ERROR_MESSAGES = {
  NOT_FOUND: '404: Not Found',
};

function getAccountType(configuration: BootstrapResponse): AccountType {
  if (configuration.Org?.settings.isPolaris) return AccountType.POLARIS;
  return AccountType.DEFAULT;
}

export const getConfigurationFromDeployment = async (
  account: LoggedInAccount,
): Promise<BootstrapResponse | null> => {
  const deploymentUrl = account.appServerUrl;
  const requestContext: RequestContext = {
    appServerUrl: account.appServerUrl,
    userKey: getKeyForLoggedInAccount(account),
    decodedUserKey: getDecodedUserKey(account),
    role: sessionStorage.getItem('currentrole') ?? undefined,
  };
  try {
    const config = await retry(async () => {
      const response = await observableFetch({
        args: [
          `${deploymentUrl}/bootstrap?deferSync=true`,
          {
            credentials: 'include',
            headers: { ...buildRequestOptions(requestContext)[1] },
          },
        ],
        observableName: 'deployment_bootstrap',
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const json = (await response.json()) as BootstrapResponse;
      if (!json.BuildVersion) {
        throw new Error('No build version specified');
      }
      window.sessionStorage.setItem('ddAppId', json.PageParams?.ddAppId ?? '');
      window.sessionStorage.setItem('ddClientToken', json.PageParams?.ddClientToken ?? '');
      bootstrapData.set(json);
      return json;
    }, RETRY_CONFIG);
    return config;
  } catch (err: unknown) {
    // eslint-disable-next-line no-console
    console.error(`Failed to get version from deployment: ${err}`);
    return null;
  }
};

// Temporary Polaris config loading from static for PrPr
async function temporaryPolarisConfigOverride(config: BootstrapResponse | null): Promise<boolean> {
  if (config == null) {
    return false;
  }
  const accountType = getAccountType(config);
  if (accountType === AccountType.POLARIS) {
    const overrideFlag = config.User?.settings?.snowflakeFeatures?.['UI_ENABLE_APEX_FEATURE_55'];

    if (overrideFlag === 'enabled') {
      try {
        const resp = await observableFetch({
          args: [`/static/polaris.json?v=${Date.now()}`],
          observableName: 'polaris_config',
        });
        const polarisConfig = await resp.json();
        const overrideVersion = polarisConfig?.version;
        // eslint-disable-next-line require-atomic-updates
        config.BuildVersion = overrideVersion;
        return true;
      } catch (e: unknown) {
        // eslint-disable-next-line no-console
        console.error(`Error retreiving Polaris override: ${e}`);
        // Catch errors - don't want to break loading if this fails
      }
    }
  }
  return false;
}

const loadScriptAsset = ({
  src,
  fetchPriority,
}: {
  src: string;
  fetchPriority?: 'high' | 'low' | 'auto';
}) => {
  return retry(async () => {
    const scriptElement = document.createElement('script');
    const scriptPromise = new Promise((resolve, reject) => {
      scriptElement.onload = (e) => resolve(e);
      scriptElement.onerror = (event, _source, _lineno, _colno, error) =>
        reject(
          error
            ? error
            : new Error(typeof event === 'string' ? event : `Error loading ${scriptElement.src}`),
        );
    });
    scriptElement.src = src;
    scriptElement.defer = true;
    if (fetchPriority) {
      scriptElement.fetchPriority = fetchPriority;
    }
    document.body.appendChild(scriptElement);

    try {
      await scriptPromise;
    } catch (err: unknown) {
      // Remove the script element before trying again
      scriptElement?.parentNode?.removeChild(scriptElement);
      throw err;
    }
  }, RETRY_CONFIG);
};

const loadCssAsset = ({ src }: { src: string }) => {
  return retry(async () => {
    const link = document.createElement('link');
    const linkPromise = new Promise((resolve, reject) => {
      link.onload = resolve;
      link.onerror = (event, _source, _lineno, _colno, error) =>
        reject(
          error
            ? error
            : new Error(typeof event === 'string' ? event : `Error loading ${link.href}`),
        );
    });
    link.rel = 'stylesheet';
    link.href = src;
    document.body.appendChild(link);

    try {
      await linkPromise;
    } catch (err: unknown) {
      // Remove the link element before trying again
      link?.parentNode?.removeChild(link);
      throw err;
    }
  }, RETRY_CONFIG);
};

export const loadBuildManifest = async ({ version }: { version: string }) => {
  let buildManifest: BuildManifest | undefined = undefined;
  let failedWithRecoverableError = false;
  try {
    buildManifest = await retry(async () => {
      const buildManifestRes = await observableFetch({
        args: [`/static/manifest-${version}.json`],
        observableName: 'manifest',
      });
      if (!buildManifestRes.ok) {
        // Abort retrying if the resource doesn't exist
        if (buildManifestRes.status === 404) {
          throw new NotRetryableError(MANIFEST_ERROR_MESSAGES.NOT_FOUND);
        }
        throw new Error(buildManifestRes.statusText);
      }
      const result = await buildManifestRes.json();
      return result;
    }, RETRY_CONFIG);
  } catch (err: unknown) {
    // RetryError's should have a 'lastError' field on them
    const lastError = (err as RetryError)?.lastError;
    if (lastError) {
      failedWithRecoverableError = lastError.message.includes(MANIFEST_ERROR_MESSAGES.NOT_FOUND);
    } else {
      // If the error is not a RetryError, something is wrong, and we shouldn't
      // assume that it was a 404 error.
      failedWithRecoverableError = false;
    }
    // eslint-disable-next-line no-console
    console.warn(`Failed to fetch build manifest: ${err}`);
  }

  // We don't know for sure if we can load the app with a legacy build manifest.
  // In these scenarios, throw an error and have the user refresh the app
  if (typeof buildManifest === 'undefined' && !failedWithRecoverableError) {
    throw new Error('Failed to load application');
  }

  // If we 404'd, we're likely on a deployment that doesn't support loading via manifest.
  // In order to make things backwards compatible, we'll synthesize a build manifest
  // object that points to assets that use the older naming convention.
  if (typeof buildManifest === 'undefined') {
    buildManifest = {
      [`numeracy.${version}.js`]: {
        file: `/static/numeracy.${version}.js`,
      },
      [`styles.${version}.css`]: {
        file: `/static/styles.${version}.css`,
      },
      [`polaris.${version}.js`]: {
        file: `/static/polaris.${version}.js`,
      },
      entrypoints: {
        numeracy: [`numeracy.${version}.js`, `styles.${version}.css`],
        polaris: [`polaris.${version}.js`, `styles.${version}.css`],
      },
    };
  }

  return buildManifest;
};

const getDependenciesForEntrypoint = ({
  buildManifest,
  entrypoint,
}: {
  buildManifest: BuildManifest;
  entrypoint: string;
}) => {
  return (buildManifest.entrypoints[entrypoint] || []).map((entry) => `/static/${entry}`);
};

/**
 * Determines where the application bootstrap fetch should be initiated.
 * If 'indexjs', we initiate it from this file, i.e. index.js
 * If 'prefetcher', we initiate it from the prefetcher bundle.
 * Otherwise, we initiate it from the main application.
 *
 * This fallback logic is meant to ensure a safe rollout.
 * When these features are fully rolled out, we should always initiate
 * the fetch from the prefetcher bundle.
 *
 * See APPS-40980
 */
function getBootstrapFetchInitiator(
  configuration: BootstrapResponse,
): 'indexjs' | 'prefetcher' | 'app' {
  // Only if this flag is explicity turned off, disable the indexjs bootstrap fetch.
  if (configuration.PageParams?.enableIndexJsBootstrap === false) {
    return 'app';
  }
  // Only if this flag is explicitly turned on, enable the prefetcher fetch.
  if (configuration.PageParams?.enablePrefetcherBootstrap) {
    return 'prefetcher';
  }
  // Otherwise initiate the fetch from index.js
  return 'indexjs';
}

export const loadApplicationBootstrapFetches = (
  configuration: BootstrapResponse,
  account: LoggedInAccount | null,
) => {
  const version = configuration.BuildVersion;

  if (
    // This flag can be set at a deployment level.
    // If bootstrapping from index.js does not work for some reason, we can
    // turn it off. See the NU_FLAGS_ENABLE_INDEX_JS_BOOTSTRAP env var.
    getBootstrapFetchInitiator(configuration) === 'indexjs' &&
    // We only run bootstrap when the version of this `index.js` bundle
    // matches the version of the main bundle. This is to be very sure that
    // we are not calling incompatible versions of bootstrap. This case
    // should be extremely rare since the bootstrap code rarely changes, but
    // this added safety measure allows us to roll out with less risk.
    //
    // The ultimate goal is to move `runApplicationBootstrapFetch` to its own bundle
    // that is version-synchronized with the main bundle, but we need to
    // investigate the impact on latency with this additional bundle fetch.
    // See APPS-39340.
    BUILD_ID === version &&
    account !== null
  ) {
    return Promise.resolve(runApplicationBootstrapFetch(account));
  }
  return undefined;
};

export function loadApplicationBootstrapFromPrefetcherBundle(
  configuration: BootstrapResponse,
  buildManifest: BuildManifest,
): Promise<void>[] {
  if (getBootstrapFetchInitiator(configuration) !== 'prefetcher') {
    return [];
  }

  const promises: Promise<void>[] = [];

  const prefetcherDependencies = getDependenciesForEntrypoint({
    buildManifest,
    entrypoint: 'prefetcher',
  });

  for (const prefetcherDependency of prefetcherDependencies) {
    // Handle script dependencies
    if (prefetcherDependency.endsWith('.js')) {
      const scriptPromise = loadScriptAsset({
        src: prefetcherDependency,
        // fetchPriority needs to be 'high' to ensure that we begin the bootstrap call
        // before we download/process numeracy
        fetchPriority: 'high',
      });
      promises.push(scriptPromise);
    }
  }

  return promises;
}

const loadVersionedAssets: AssetsLoader = async (
  configuration: BootstrapResponse,
  account: LoggedInAccount | null,
) => {
  const version = configuration.BuildVersion;
  const accountType = getAccountType(configuration);
  const shouldLoadSignin = !account && /^\/?$/iu.test(window.location.pathname);

  numeracy.bootstrapFetches = numeracy.bootstrapFetches || {};
  try {
    // There's no point in running the application bootstrap if we don't have an account
    if (!shouldLoadSignin) {
      numeracy.bootstrapFetches.application = loadApplicationBootstrapFetches(
        configuration,
        account,
      );
    }
  } catch (err: unknown) {
    numeracy.bootstrapFetches.application = undefined;
    // eslint-disable-next-line no-console
    console.warn(`Failed to run bootstrap fetches: ${err}`);
  }

  // Append information about OSS licenses
  const ossLicenseComment = document.createComment(
    `License information for included third-party packages can be found at ${window.location.origin}/static/oss-licenses-${version}.json`,
  );
  document.body.appendChild(ossLicenseComment);

  window.higherFetchPriorityScript = Math.random() > 0.5;
  // In development we don't use split chunks or a manifest, so only load the numeracy and prefetcher bundles.
  if (DEV_SERVER) {
    if (shouldLoadSignin) {
      await loadScriptAsset({ src: `/static/signin.${version}.js` });
      return;
    }

    if (AccountType.POLARIS === accountType) {
      await loadScriptAsset({ src: `/static/polaris.${version}.js` });
    } else {
      await Promise.all([
        loadScriptAsset({
          src: `/static/prefetcher.${version}.js`,
          ...(window.higherFetchPriorityScript && { fetchPriority: 'high' }),
        }),
        loadScriptAsset({
          src: `/static/numeracy.${version}.js`,
          ...(window.higherFetchPriorityScript && { fetchPriority: 'high' }),
        }),
      ]);
    }
    return;
  }

  // Load the manifest file
  const buildManifest = await loadBuildManifest({ version });

  // Keep track of assets being loaded
  const promises = loadApplicationBootstrapFromPrefetcherBundle(configuration, buildManifest);

  // Determine the static assets we need to load
  const numeracyDependencies = getDependenciesForEntrypoint({
    buildManifest,
    entrypoint: shouldLoadSignin
      ? 'signin'
      : AccountType.POLARIS === accountType
      ? 'polaris'
      : 'numeracy',
  });

  for (const numeracyDependency of numeracyDependencies) {
    // Handle script dependencies
    if (numeracyDependency.endsWith('.js')) {
      const scriptPromise = loadScriptAsset({
        src: numeracyDependency,
        ...(window.higherFetchPriorityScript && { fetchPriority: 'high' }),
      });
      promises.push(scriptPromise);
    }

    // Handle stylesheet dependencies
    if (numeracyDependency.endsWith('.css')) {
      const linkPromise = loadCssAsset({ src: numeracyDependency });
      promises.push(linkPromise);
    }
  }

  return await Promise.all(promises);
};

/**
 * Displays an error screen, telling the user to refresh the app.
 * This is used when the app fails to load.
 */
const displayRefreshAppError = () => {
  const BOOTLOADER_ERROR_PAGE_CONTENT = `
  <style>
    body {
      margin: 0;
    }

    .global-app-error {
      font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100vh;
      background-color: #f7f7f7ff;
    }

    .primary-error-message {
      color: #1e252fff;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 0.5em;
    }

    .subtitle-error-message {
      color: #5d6a85ff;
      font-size: 16px;
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 2em;
    }
  </style>
  <div class="global-app-error">
    <h1 class="primary-error-message">Something went wrong</h1>
    <h3 class="subtitle-error-message">Please refresh the page</h3>
  </div>
  `;
  const rootNode = document.getElementById('reactRoot');
  if (!rootNode) {
    throw new Error('Invalid page');
  }
  rootNode.innerHTML = BOOTLOADER_ERROR_PAGE_CONTENT;
};

/**
 * Creates an asset loader which attempts to load versioned assets for the application.
 * If we encounter a failure while trying to load the assets, we'll display an
 * error screen telling the user to refresh the page to try loading the app again
 */
export function createAssetsLoaderWithErrorScreen(loader: AssetsLoader): AssetsLoader {
  return async function versionedAssetsLoader(
    configuration: BootstrapResponse,
    account: LoggedInAccount | null,
  ) {
    try {
      await loader(configuration, account);
    } catch (err: unknown) {
      // eslint-disable-next-line no-console
      console.error(`Failed to load application: ${err}`);
      displayRefreshAppError();
    }
  };
}

/**
 * Loads the application's assets -- kicking off the initialization process.
 *
 * There are 3 branching paths for which assets are loaded:
 * 1. **Beach Version:** If the included version is for a beach, always load those assets
 * 2. **Deployment Version:** If the user has an active account, get the proper
 *    application version from the deployment server and load those assets. This ensures
 *    that client code is compatible with code running on the server-side
 * 3. **Included Version:** The bootloader includes a default version, which is baked
 *    into the BUILD_ID constant during the build process. This version will be
 *    used until the user signs into an account
 *
 * @param {string} includedVersion The version that is baked into this asset at build time
 * @param {Function} loader A function that loads assets for a provided version
 * @param {URL} url A URL that represents the location of the document
 */
export const loadApplicationAssets = async (
  includedVersion: string,
  loader: AssetsLoader,
  url?: URL,
) => {
  window.preconnectEnabled = Math.random() > 0.5;
  window.preconnectAllAccountsEnabled = Math.random() > 0.5;
  const activeAccount = getActiveAccount(
    url ?? new URL(window.location.href),
    window.preconnectEnabled,
  );
  const configuration = activeAccount?.appServerUrl
    ? await getConfigurationFromDeployment(activeAccount)
    : null;

  const polarisOverride = await temporaryPolarisConfigOverride(configuration);

  // Store the csrfToken for the session
  if (configuration?.PageParams?.appServerUrl && configuration?.PageParams?.csrfToken) {
    setCsrfToken(configuration.PageParams.appServerUrl, configuration.PageParams.csrfToken);
  }

  // Given that the bootloader does not have access to Datadog RUM, we set analytics information on the window object
  // and have code in the awaitBootstrapAndLocalization function to send this information to DataDog RUM.
  // I disabled the eslint require-atomic-updates rule because it is a false positive.
  // The require-atomic-updates rule is fired due to this condition https://eslint.org/docs/latest/rules/require-atomic-updates#properties
  // This assignment does not have a dependency on any value read from the window object above, but estlint does not know that
  // and assumes that because we read window.preconnectEnabled in line 545, this code block might depend on it.
  // eslint-disable-next-line require-atomic-updates
  window.bootloaderAnalytics = {
    versionAnalytics: {
      bootstrapAndBuiltinVersionMatch: configuration?.BuildVersion === includedVersion,
      calledBootstrap: configuration !== null,
      polarisOverrideEnabled: polarisOverride,
    },
  };

  // Always serve beach assets, regardless of deployment.
  if (
    (includedVersion.startsWith('PR-') || window.NUMERACY_VERSION_OVERRIDE != null) &&
    !polarisOverride
  ) {
    await loader(
      {
        ...configuration,
        BuildVersion: window.NUMERACY_VERSION_OVERRIDE ?? includedVersion,
        PageParams: {
          ...configuration?.PageParams,
          enableIndexJsBootstrap: true,
          enablePrefetcherBootstrap: true,
        },
      },
      activeAccount,
    );
    return;
  }

  // Attempt to load assets that have the same application version as the deployment server
  if (configuration?.BuildVersion) {
    await loader(configuration, activeAccount);
    return;
  }

  // If there is no active account, we'll use the included version
  return await loader({ BuildVersion: includedVersion }, activeAccount);
};

(async () => {
  const qs = Object.fromEntries(new URLSearchParams(window.location.search));
  // Force index to use numeracy_version_override url as the version if set.
  // Will only apply if the version of index actually specified in entrypoint_version_override supports it.
  if (qs.numeracy_version_override) {
    window.NUMERACY_VERSION_OVERRIDE = qs.numeracy_version_override;
  }

  if ('entrypoint_version_override' in qs && BUILD_ID !== qs.entrypoint_version_override) {
    // If entrypoint_version_override url param is set, short-circuit and load/execute the version specified.
    const buildManifest = await loadBuildManifest({ version: qs.entrypoint_version_override });
    await loadScriptAsset({ src: (buildManifest['index.js'] as { file: string }).file });
    return;
  }

  // no-op unless we are in a webpack build.
  if (BUILD_ID) {
    // Prevent code from executing when this file is imported by Jest tests
    // eslint-disable-next-line no-restricted-globals
    if (typeof jest === 'undefined') {
      const versionedAssetsLoader = createAssetsLoaderWithErrorScreen(loadVersionedAssets);
      loadApplicationAssets(BUILD_ID, versionedAssetsLoader);
    }
  }
})();
