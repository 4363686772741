import cookies from 'browser-cookies';
import { AUTH_PARAMS_COOKIE } from '../constants/AUTH_PARAMS_COOKIE';
import type { LoggedInAccount } from '../types/LoggedInAccount';

/* Copied from from UserRequestContext.ts */
export const getAccountFromAuthCookie = (url: URL) => {
  const cookieKey = url.searchParams.get(AUTH_PARAMS_COOKIE);
  const paramsCookie = cookies.get(cookieKey || AUTH_PARAMS_COOKIE);
  if (paramsCookie) {
    try {
      return JSON.parse(atob(paramsCookie)) as LoggedInAccount;
    } catch (e: unknown) {
      return null;
    }
  }
  return null;
};
