import { numeracy } from '../constants';

const DATADOG_NAME_PREFIX = 'prefetch_bootstrap__';

/**
 * This version of `fetch` must be used in
 * this subproject so its fetches can be instrumented
 * before Datadog is initialized.
 *
 * Note: we do not implement this as a monkeypatch to
 * `fetch` because doing so would bleed into the main application.
 */
export function observableFetch({
  args,
  observableName,
}: {
  args: Parameters<typeof window.fetch>;
  observableName: string;
}): ReturnType<typeof window.fetch> {
  numeracy.bootstrapFetches = numeracy.bootstrapFetches || {};
  numeracy.bootstrapFetches.observableFetches = numeracy.bootstrapFetches.observableFetches || {
    fetches: [],
  };

  const response = fetch(...args);
  numeracy.bootstrapFetches.observableFetches.fetches.push({
    args,
    response,
    datadogName: `${DATADOG_NAME_PREFIX}${observableName}`,
    // The performance API always logs the absolute path of the fetch
    // even if it had been called with a relative path, so we need to
    // log the absolute path.
    absolutePath: new URL(args[0].toString(), document.baseURI).href,
  });

  return response;
}
