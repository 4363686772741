import cookies from 'browser-cookies';
import { CURRENT_ROLE } from '../constants/CURRENT_ROLE';
import { CURRENT_SECONDARY_ROLES } from '../constants/CURRENT_SECONDARY_ROLES';
import { SNOWFLAKE_CONTEXT_NAME } from '../constants/SNOWFLAKE_CONTEXT_NAME';
import type { LoggedInAccount } from '../types/LoggedInAccount';
import type { LoggedInAccounts } from '../types/LoggedInAccounts';
import { getAccountFromAuthCookie } from './getAccountFromAuthCookie';
import { getAccountFromContext } from './getAccountFromContext';
import { getAccountFromUrl } from './getAccountFromUrl';
import { getAccountFromUrlAndContext } from './getAccountFromUrlAndContext';
import { getKeyForLoggedInAccount } from './getKeyForLoggedInAccount';

/* 
There are 3! potential sources of truth for active account.

1) Auth cookie. This will only be set immediately after a user logs in.
2) URL. Preferable over snowflakeContext. Pasting a URL for Account B into a window signed in to Account A should still work.
3) snowflakeContext sessionStorage/cookie. If you happen to navigate to app.snowflake.com manually, this path will be hit.
*/
export const getActiveAccount = (url: URL, preconnectEnabled: boolean): LoggedInAccount | null => {
  /*
   * Read the "error" query parameter. If the error query parameter is present, short circuit all this business
   * logic and return null to force the login page to render instead indicating auth failure. The previous
   * behaviour here would find any available account to login, causing users to think login succeeded when
   * in actuality it failed
   */
  const urlSearchParams = new URLSearchParams(window.location.search);
  const errorCode = urlSearchParams.get('error');

  // There is a constant for this, but that constant is for some reason in dx-models.
  // Reproducing it here because we don't want to increase the size of the loader to import dx-models
  if (errorCode === 'LOGIN_FAILED') {
    return null;
  }

  const loggedInAccounts = JSON.parse(
    localStorage.getItem('loggedInAccounts') ?? '{}',
  ) as LoggedInAccounts;

  const accountFromContext = getAccountFromContext(loggedInAccounts);
  const account =
    getAccountFromAuthCookie(url) ??
    getAccountFromUrlAndContext(getAccountFromUrl(url, loggedInAccounts), accountFromContext);

  const appServerUrl = account?.appServerUrl;
  if (appServerUrl && preconnectEnabled) {
    // Add preconnect dynamically
    const preconnectLink = document.createElement('link');
    preconnectLink.rel = 'preconnect';
    preconnectLink.href = appServerUrl;
    document.head.appendChild(preconnectLink);
  }

  if (account) {
    /* If the snowflakeContext is updated, we need to clear roles as they are no longer relevant. */
    if (accountFromContext !== account) {
      sessionStorage.removeItem(CURRENT_ROLE);
      sessionStorage.removeItem(CURRENT_SECONDARY_ROLES);
    }
    const key = getKeyForLoggedInAccount(account);
    sessionStorage.setItem(SNOWFLAKE_CONTEXT_NAME, key);
    cookies.set(SNOWFLAKE_CONTEXT_NAME, key);
  }
  return account;
};
