import type { LoggedInAccount } from '../types/LoggedInAccount';
import { toUserKeyStatic } from './toUserKeyStatic';

export function getDecodedUserKey(account: LoggedInAccount): string {
  // Reverse the encoding done in oauth-complete-page to get back to the correct user name and make a decodedUserKey.
  // This ensures that the X-Snowflake-Context-Encoded header sent in REST API calls is correct.
  // Note that userKey will contain the encoded form, since otherwise it is not a valid key in cookies and session storage.
  const decodedUsername = decodeURIComponent(escape(account.username));
  return toUserKeyStatic(decodedUsername, account.url);
}
