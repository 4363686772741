import type { LoggedInAccount } from '../types/LoggedInAccount';
import type { LoggedInAccounts } from '../types/LoggedInAccounts';
import { getOrgAndAliasFromRegionLessURL } from './getOrgAndAliasFromRegionLessURL';

/*
Finds a matching account based on a regionless URL. If there are multiple users for the same account, will return the most recently used one.
*/
export const getAccountFromRegionlessURL = (url: URL, accounts: LoggedInAccounts) => {
  const [urlOrg, urlAlias] = getOrgAndAliasFromRegionLessURL(url);
  let match: LoggedInAccount | null = null;

  for (const userKey in accounts) {
    const curr = accounts[userKey];
    const currOrg = curr.snowflakeOrgName?.toLowerCase();
    const currAlias = curr.accountAlias?.toLowerCase();
    if (currOrg === urlOrg && currAlias === urlAlias) {
      if (!match || (curr.updated && curr.updated > (match.updated ?? 0))) {
        match = curr;
      }
    }
  }

  return match;
};
