import { numeracy } from '../constants';
import type { RequestContext } from '../types/RequestContext';
import { generateSnowflakeEncodedHeaderValue } from './generateSnowflakeEncodedHeaderValue';
import { isIso88591String } from './isIso88591String';
import { isPrivatelink } from './isPrivatelink';

export const requestHeadersMap = {
  userId: 'X-Numeracy-UserId',
  clientVersion: 'X-Numeracy-Client-Version',
  privateLinkHost: 'X-Snowflake-Privatelink-Host',
  snowflakeContext: 'X-Snowflake-Context',
  encodedSnowflakeContext: 'X-Snowflake-Context-Encoded',
  snowflakeRole: 'X-Snowflake-Role',
  encodedSnowflakeRole: 'X-Snowflake-Role-Encoded',
  csrfToken: 'X-CSRF-Token',
  // used only in dx-api
  encodedSnowflakeSecondaryRoles: 'X-Snowflake-Secondary-Roles-Encoded',
} as const;

/**
 * This function builds the options needed by `fetch` given a {@type RequestContext}
 */
export function buildRequestOptions(
  requestContext: RequestContext,
  userId?: number,
  providedOptions?: RequestInit,
  logger?: { error: (err: unknown, params: { message: string }) => void },
): [RequestInit, Record<string, string>] {
  const options: RequestInit = { ...(providedOptions ?? {}) };
  const headers = { ...(options.headers ?? {}) } as Record<string, string>;
  options.credentials = 'include';

  if (userId != null) {
    headers[requestHeadersMap.userId] = `${userId}`;
  }
  if (numeracy.clientVersion) {
    headers[requestHeadersMap.clientVersion] = numeracy.clientVersion;
  }
  if (isPrivatelink(window.location.hostname)) {
    headers[requestHeadersMap.privateLinkHost] = window.location.origin;
  }
  // Temporarily send both X-Snowflake-Context and X-Snowflake-Context-Encoded
  // header on all API calls while this rolls out.
  // Coldbrew and Nuweb will prefer the -Encoded version which fixes some issues
  // like special characters and significant whitespace.
  if (requestContext.userKey) {
    headers[requestHeadersMap.snowflakeContext] = requestContext.userKey;
  }
  if (requestContext.decodedUserKey) {
    try {
      headers[requestHeadersMap.encodedSnowflakeContext] = generateSnowflakeEncodedHeaderValue(
        requestContext.decodedUserKey,
      );
    } catch (err: unknown) {
      // TODO: validate no error is ever thrown here and remove try/catch. See APPS-22830.
      logger?.error(err, { message: 'Failed to generate x-snowflake-context-encoded' });
    }
  }

  if (requestContext.role) {
    // We are in the process of migrating from the `x-snowflake-role` header, which sends the raw
    // text of the Snowflake role to the server, to `x-snowflake-role-encoded`, which uses URL
    // encoding. Headers are supposed to be ASCII only, and while characters in ISO 8859-1 but not
    // ASCII will actually still work, any characters outside ISO 8859-1 will cause `fetch` to error
    // with an error like:
    //
    //   Failed to execute 'fetch' on 'Window': Failed to read the 'headers' property from
    //   'RequestInit': String contains non ISO-8859-1 code point.
    //
    // We will eventually deprecate `x-snowflake-role`, but until we are sure clients are always
    // successfully encoding `x-snowflake-role-encoded`, do not set any values for
    // `x-snowflake-role` that will crash `fetch`. In these cases `x-snowflake-role-encoded` should
    // be available and we can support non-ASCII roles sooner.
    if (isIso88591String(requestContext.role)) {
      headers[requestHeadersMap.snowflakeRole] = requestContext.role;
    }
    try {
      headers[requestHeadersMap.encodedSnowflakeRole] = generateSnowflakeEncodedHeaderValue(
        requestContext.role,
      );
    } catch (err: unknown) {
      // TODO: validate no error is ever thrown here and remove try/catch. See APPS-22830.
      logger?.error(err, { message: 'Failed to generate x-snowflake-role-encoded' });
    }
  }

  if (
    numeracy.stores?.user?.parameters?.['UI_ENABLE_SECONDARY_ROLES_IN_PROVIDER_STUDIO'] ===
      'enabled' &&
    requestContext.secondaryRoles
  ) {
    try {
      headers[requestHeadersMap.encodedSnowflakeSecondaryRoles] =
        generateSnowflakeEncodedHeaderValue(requestContext.secondaryRoles);
    } catch (err: unknown) {
      // TODO: validate no error is ever thrown here and remove try/catch. See APPS-22830.
      logger?.error(err, { message: 'Failed to generate X-Snowflake-Secondary-Roles-Encoded' });
    }
  }

  return [options, headers];
}
